import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { makeStyles } from "@material-ui/styles"
import Navbar from "../components/navbar/navbar";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    background: 'linear-gradient(180deg, #F3DCC6 26.04%, #FCCFC9 80.21%)',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
  },
  contactInfo: {
    paddingLeft: 16,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 96,
    },
    color: '#192C4F',
  }
}));


const ContactPage = () => {

const classes = useStyles();

 return (<Layout>
    <SEO
     title="Kontakt - The Måler - Målare i Jämtland, info@themaler.se"
     description="Kontakt - The Måler - Åre, Järpen, Jämtland. Fredrik Nygren verksam yrkesman med gesällbrev. Färgsätter fasad, tak, väggar, snickerier, luckor, fönster, dörrar och möbler."
    />
    <div className={classes.container}>
      <Navbar page="CONTACT" />
      <div className={classes.contactInfo}>
       <Typography style={{ paddingBottom: 60 }} variant="h2">070-278 12 26</Typography>
        <Typography variant="h3">Fredrik Nygren</Typography>
        <Typography variant="h3">Notvallsvägen 21</Typography>
       <Typography style={{ paddingBottom: 60 }} variant="h3">837 31 Järpen</Typography>
        <Typography variant="h3">info@themaler.se </Typography>
        </div>
    </div>
  </Layout>
)
}

export default ContactPage
